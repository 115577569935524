import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCustomerOrderByPageNo,
  getCustomerOrders,
  getCustomerWalletOrders,
  orderDetailApi,
  triggerEmailVerificationApi,
  verifyEmailOtpUser,
} from "./ProfileApis";
import * as TYPES from "./ProfileTypes";
import * as ACTIONS from "./ProfileActions";
import { dispatchSnackbarSuccess } from "../../utils/Shared";

export function* sagasGetCustomerOrders(payload) {
  try {
    const data = yield call(getCustomerOrders, payload);
    yield put(ACTIONS.getCustomerOrderReceive(data));
  } catch (error) {
    // console.log("errorrrrr", error);
  }
}

export function* sagasTriggerEmailVerification(payload) {
  try {
    const { data, status } = yield call(triggerEmailVerificationApi, payload);
    // console.log("dataaaaaaaaa", data);
    // console.log("statusssssssss", status);
    dispatchSnackbarSuccess(data?.message);
    yield put(ACTIONS.getTriggeredEmailResponse(data?.message));
  } catch (error) {
    console.log("errorrrrr", error);
  }
}

export function* sagasVerfiyEmailOtp(payload) {
  try {
    const response = yield call(verifyEmailOtpUser, payload);
    if (response?.status === 200) {
      yield put(ACTIONS.verifiedEmailResponse(response?.data?.message));
      yield put(ACTIONS.isEmailVerified(true));
      dispatchSnackbarSuccess(response?.data?.message);
    } else {
      yield put(ACTIONS.isEmailVerified(false));
    }
  } catch (error) {
    console.log("errorrrrrrr", error);
    yield put(ACTIONS.isEmailVerified(false));
  }
}

export function* sagasGetCustomerWalletOrders(payload) {
  try {
    const data = yield call(getCustomerWalletOrders, payload?.payload);
    yield put(ACTIONS.setCustomerWalletOrders(data?.data));
  } catch (error) {
    console.log("errorrrrr", error);
  }
}

export function* sagaGetCustomerOrderByPageNo(payload) {
  try {
    const response = yield call(getCustomerOrderByPageNo, payload);
    yield put(ACTIONS.getCustomerOrderReceive(response));
  } catch (error) {}
}

export function* sagasOrderDetail(payload) {
  console.log("payloiaddddd", payload);
  try {
    const response = yield call(orderDetailApi, payload);
    if (response?.status === 200) {
      yield put(ACTIONS.verifiedEmailResponse(response?.data?.message));
      yield put(ACTIONS.isEmailVerified(true));
      dispatchSnackbarSuccess(response?.data?.message);
    } else {
      yield put(ACTIONS.isEmailVerified(false));
    }
  } catch (error) {
    console.log("errorrrrrrr", error);
    yield put(ACTIONS.isEmailVerified(false));
  }
}

export function* customerOrdersSaga() {
  yield takeLatest(TYPES.GET_CUSTOMER_ORDER_REQUEST, sagasGetCustomerOrders);
}

export function* customerWalletOrdersSaga() {
  yield takeLatest(
    TYPES.GET_CUSTOMER_WALLET_REQUEST,
    sagasGetCustomerWalletOrders
  );
}

export function* customerOrderDetailSaga() {
  yield takeLatest(TYPES.GET_ORDER_DETAIL_REQUEST, sagasOrderDetail);
}

export function* customerOrderByPageNoSaga() {
  yield takeLatest(
    TYPES.GET_CUSTOMER_HISTORY_BY_PAGE_NO,
    sagaGetCustomerOrderByPageNo
  );
}

export function* customerTriggerEmailVerification() {
  yield takeLatest(
    TYPES.TRIGGER_EMAIL_VERIFICATION,
    sagasTriggerEmailVerification
  );
}

export function* customerEmailOtpVerification() {
  yield takeLatest(TYPES.VERIFY_EMAIL_OTP, sagasVerfiyEmailOtp);
}
