import * as types from "./RestaurantTypes";

const INITIAL_STATE = {
  hotelKey: null,
  tableId: null,
  userId: null,
  tablePeopleCount: null,
  tableDetails: null,
  isHotelFetchCompleted: false,
  hotel: null,
  tables: null,
  appVersion: null,
  isTodayHoliday: false,
  todayHolidayLabel: null,
  isProfileSecondPage: false,
  userData: null,
  walletPointsInProgress: false,
  isWalletError: false,
  isAppFlavour: false,
  isMobileApp: false,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TABLE_ID:
      return {
        ...state,
        tableId: action.payload,
      };
    case types.SET_TABLE_PEOPLE_COUNT:
      return {
        ...state,
        tablePeopleCount: action.payload,
      };
    case types.SET_APP_VERSION:
      return {
        ...state,
        appVersion: action.payload,
      };
    case types.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case types.SET_TABLE_DETAILS:
      return {
        ...state,
        tableDetails: action.payload,
      };
    case types.SET_IS_MOBILE_APP:
      return {
        ...state,
        isMobileApp: action.payload,
      };
    case types.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        hotelKey: action.payload,
        isHotelFetchCompleted: false,
        hotel: null,
      };
    case types.SET_IS_APP_FLAVOUR:
      return {
        ...state,
        isAppFlavour: action.payload,
      };
    case types.GET_RESTAURANT_RECEIVE:
      return {
        ...state,
        isHotelFetchCompleted: true,
        hotel: action.payload?.[0],
      };
    case types.IS_PROFILE_SECOND_PAGE:
      return {
        ...state,
        isProfileSecondPage: action.payload,
      };
    case types.IS_TODAY_HOLIDAY:
      return {
        ...state,
        isTodayHoliday: action.payload.isTodayHoliday,
        todayHolidayLabel: action.payload.todayHolidayLabel,
      };
    case types.CLEAR_RESTURANT:
      return {
        ...state,
        isHotelFetchCompleted: null,
        hotel: null,
      };
    case types.CLEAR_TABLE:
      return {
        ...state,
        tableId: null,
        tablePeopleCount: null,
        tableDetails: null,
      };
    case types.GET_RESTAURANT_TABLES_RECEIVE:
      return {
        ...state,
        tables: action.payload,
      };
    case types.GET_WALLET_POINTS_RECEIVE:
      return {
        ...state,
        userData: action.payload,
      };
    case types.RESET_USER_WALLET_DETAILS:
      return {
        ...state,
        userData: null,
      };
    case types.SET_WALLET_ERROR:
      return {
        ...state,
        isWalletError: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
