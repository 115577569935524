import * as Yup from "yup";
import "yup-phone";

export default Yup.object().shape({
  customer_name: Yup.string().required("Required"),
  customer_phone: Yup.string()
    .required("Phone Number is Required")
    .test("is-valid-phone", "Must be a valid phone number!", (value) =>
      Yup.string().phone().isValidSync(value)
    ),
  customer_email: Yup.string().email("Invalid Email").required("Required"),
});
