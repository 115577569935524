export const GET_CUSTOMER_ORDER_REQUEST = "GET_CUSTOMER_ORDER_REQUEST";
export const CUSTOMER_ORDER_RECEIVE = "CUSTOMER_ORDER_RECEIVE";
export const IS_CUSTOMER_ORDER_INPROGRESS = "IS_CUSTOMER_ORDER_INPROGRESS";
export const GET_CUSTOMER_HISTORY_BY_PAGE_NO =
  "GET_CUSTOMER_HISTORY_BY_PAGE_NO";
export const SET_REDUMPTION_RATIO = "SET_REDUMPTION_RATIO";
export const SET_USED_WALLET_POINT = "SET_USED_WALLET_POINT";
export const SET_PRICE_WALLET_POINT = "SET_PRICE_WALLET_POINT";
export const RESET_WALLET = "RESET_WALLET";
export const CLEAR_USED_WALLET_POINTS = "CLEAR_USED_WALLET_POINTS";
export const UPDATE_USED_WALLET_POINTS = "UPDATE_USED_WALLET_POINTS";
export const SET_SELECTED_WALLETS = "SET_SELECTED_WALLETS";
export const RESET_SELECTED_WALLETS = "RESET_SELECTED_WALLETS";
export const ADD_USED_WALLET_POINTS = "ADD_USED_WALLET_POINTS";
export const GET_CUSTOMER_WALLET_REQUEST = "GET_CUSTOMER_WALLET_REQUEST";
export const SET_CUSTOMER_WALLET_ORDERS = "SET_CUSTOMER_WALLET_ORDERS";
export const TRIGGER_EMAIL_VERIFICATION = "TRIGGER_EMAIL_VERIFICATION";
export const GET_TRIGGERED_EMAIL_RESPONSE = "GET_TRIGGERED_EMAIL_RESPONSE";
export const VERIFY_EMAIL_OTP = "VERIFY_EMAIL_OTP";
export const VERIFIED_EMAIL_RESPONSE = "VERIFIED_EMAIL_RESPONSE";
export const IS_EMAIL_VERIFIED = "IS_EMAIL_VERIFIED";
export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const SET_ORDER_DETAIL_SUCCESS = "SET_ORDER_DETAIL_SUCCESS";
export const IS_ORDER_DETAIL_LOADING = "IS_ORDER_DETAIL_LOADING";
export const SET_DIRECT_LOGIN = "SET_DIRECT_LOGIN";
