import { call, put, select, takeLatest } from "redux-saga/effects";

import { createUser, loginUser } from "./UserApis";
import * as ACTIONS from "./UserAction";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./UserTypes";
import { useSelector } from "react-redux";
import History from "../../routes/History";

// Replace with your sagas
export function* sagasCreateUser({ payload }) {
  try {
    const response = yield call(createUser, payload);
    yield put(ACTIONS.getUser({ ...response }));

    if (response) {
      dispatchSnackbarSuccess("User details updated");
    }
  } catch (err) {
    // dispatchSnackbarError(err.response.data);
    dispatchSnackbarError("Something went wrong, please try again later");
  }
}

export function* sagasLoginUser({ payload }) {
  try {
    const response = yield call(loginUser, payload);
    if (response) {
      yield put(ACTIONS.getUser(response));
      // console.log("response", response);
      dispatchSnackbarSuccess("Login Successfully");
      // payload.cbl();

      if (payload?.isDirectLogin) {
        History.push(`/${payload?.hotelKey}/profile`);
      }
    }
    // } else {
    //   payload.cbs();
    //   // dispatchSnackbarError("Account with this phone number does not exists!");
    // }
  } catch (err) {
    console.log("error ", err);
    // dispatchSnackbarError(err.response.data);
    dispatchSnackbarError("Something went wrong, please try again later");
  }
}

export function* CreateUserSaga() {
  yield takeLatest(TYPES.CREATE_USER_REQUEST, sagasCreateUser);
}

export function* LoginUserSaga() {
  yield takeLatest(TYPES.LOGIN_USER_REQUEST, sagasLoginUser);
}
