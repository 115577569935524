import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Icons
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Styles
import useStyles from "./HotelDetails.style";
import { Paper, useMediaQuery } from "@material-ui/core";
// Controls
// import IconBtn from "./../Controls/IconButton/IconButton";

const HotelDetails = ({
  desc = false,
  color = "white",
  darkTheme,
  isCart = false,
}) => {
  const classes = useStyles();
  const { theme } = useSelector((state) => state?.theme);
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { hotel } = useSelector((state) => state.hotel);
  const hotelAddress = useMemo(() => {
    if (hotel) {
      const address = hotel.address[0];
      return `${address.streetName}, ${address.city}, ${address.country}, ${address.postalCode}`;
    }
    return "";
  }, [hotel]);

  function isMobile() {
    return window.innerWidth <= 768; // You can adjust the breakpoint as needed
  }

  const isBase64 = theme?.header_logo?.startsWith("data:image");
  return (
    <div className={classes.container}>
      {theme?.header_logo && !isCart ? (
        // Otherwise, render the image with regular URL
        <div className={classes.headerLogoDiv}>
          <img
            src={`data:image/png;base64, ${theme?.header_logo}`}
            alt="Header Logo"
            className={classes.headerLogo}
          />
        </div>
      ) : (
        <Typography
          className={isCart ? classes.cartTitle : clsx(classes.title)}
          // style={{ color: darkTheme ? "white" : "black" }}
          variant="h1"
          // noWrap
          display="block"
        >
          {/* {APP_NAME} */}
          {hotel ? hotel.hotelName : ""}
        </Typography>
      )}
      {!isMobile() && (
        <Typography
          className={clsx(
            isCart ? classes.locationSpl : classes.location
            // color === "black" && classes.blackColor
          )}
          variant="h6"
        >
          <LocationOnOutlinedIcon />
          {hotelAddress ? `${hotelAddress}` : ""}
        </Typography>
      )}
    </div>
  );
};

export default HotelDetails;
