import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import clsx from "clsx";
// Material
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// Material Component
import Modal from "../../../components/Material/Modal/Modal";
// Components
import SelectOrderType from "../../../components/Checkout/SelectOrderType/SelectOrderType";
import ErrorModal from "../../../components/Error";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
import useCheckoutForm from "../../../CustomHooks/useCheckoutForm";
// Store
import {
  getRestaurant,
  getUserWalletPointsReceive,
  getUserWalletPointsRequest,
} from "./../../../store/Restaurant/RestaurantActions";
// Utils
import {
  dispatchErrorMessageAsWarning,
  dispatchResetErrorMessage,
} from "../../Shared";
// Constants
import { ERROR_TYPES, MESSAGES } from "../../Constants";

// import LogRocket from 'logrocket';
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  clearCoupon,
  setOrderTypeModal,
} from "../../../store/Cart/CartActions";
import {
  resetWallet,
  setDirectLogin,
} from "../../../store/Profile/ProfileActions";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import { clearLocation } from "../../../store/Location/LocationActions";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import LoginDialogCon from "../../../components/Checkout/LoginDetailsForm/LoginDetailsForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles((theme) => ({
  orderTypeModalContainer: {
    width: "350px",
    height: "auto",
  },
  orderTypeModalMobileContainer: {
    width: "100%",
    height: "70vh",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  orderTypeModal: {
    padding: theme.spacing(2.5),
  },
  modalTitleSection: {
    marginBottom: theme.spacing(1.6),
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    color: theme.palette.common.themeFontColor,
  },
  dialogPaper: {
    background: "white !important", // White background
    margin: 0, // Remove default margins
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white !important",
  },
}));

function InnerLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:495px)");
  // Find the index of "table_id" in the URL

  // LogRocket.identify(`${id}`, {
  //   name: 'sipo',
  // });
  const { isHotelFetchCompleted, hotel, tableId, hotelKey } = useSelector(
    (state) => state.hotel
  );
  const { isDirectLogin } = useSelector((state) => state.profile);
  const error = useSelector((state) => state.error);
  const user = useSelector((state) => state.user);
  const { orderTypeModal, selectedCoupon } = useSelector((state) => state.cart);
  const [loginOpen, setLoginOpen] = useState(false);

  const [isOrderTypeUpdated, setOrderTypeUpdates] = useState(false);
  const [openOrderTypeModal, setOpenOrderTypeModal] = useState(true);
  const { resetWalletProducts, RemoveItemCoupon } = useWalletPoints();
  const { getOrderTypes, isMultipleOrderType, hotelOrderStatus } =
    useDynamicHotelData();
  const { formValues, setFormValues } = useCheckoutForm();
  const checkHotelOpening = () => {
    const match = hotel.workingInfo.find((w) => w.day === moment().day());
    if (match) {
      return true;
    } else return false;
  };

  const isErrorMode = useMemo(() => {
    return error.type === ERROR_TYPES.RESTURANT && error.show;
  }, [error.type, error.show]);

  const isProfilePage = useMemo(() => {
    return /profile/.test(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Parse the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const directLogin = urlParams.get("directLogin");
    // console.log("directLogin", directLogin);
    if (directLogin) {
      if (!user) {
        // console.log("workinggg");
        // Display your login popup here
        setLoginOpen(true);
        dispatch(setDirectLogin(true));
      } else {
        history.push(`/${hotelKey}/profile`);
      }
    } else {
      // dispatch(setDirectLogin(false));
      setLoginOpen(false);
    }
  }, [user]);

  useEffect(() => {
    // if (!location.pathname.includes(`/${id}/cart`)) {
    dispatch(getRestaurant(id, tableId));
    if (user?.uid) {
      dispatch(getUserWalletPointsRequest({ uid: user?.uid, online_key: id }));
    }
    // }
  }, [id, dispatch, tableId, user]);

  useEffect(() => {
    if (
      isHotelFetchCompleted &&
      hotel &&
      hotel.workingInfo &&
      hotel.workingInfo.length
    ) {
      // const holidayDetails = checkHoliday();
      // if (holidayDetails) {
      //   dispatchErrorMessageAsWarning({
      //     title: "Alert",
      //     message: holidayDetails.label || "Today is holiday",
      //   });

      //   return;
      // }

      if (!checkHotelOpening()) {
        dispatchErrorMessageAsWarning({
          title: MESSAGES.RESTURANT_OPENING_CLOSED.TITLE,
          message:
            hotel.is_preorder === 1
              ? MESSAGES.RESTURANT_OPENING_CLOSED.MESSAGE
              : "",
        });
      } else if (!hotelOrderStatus) {
        dispatchErrorMessageAsWarning({
          title: "Alert",
          message:
            hotel?.online_inactive_reason != undefined
              ? hotel?.online_inactive_reason
              : "Can't accept online orders right now",
        });
      }
      // else {
      //   dispatchResetErrorMessage();
      // }
    }
    // return () => {
    //   dispatchResetErrorMessage();
    // };
  }, [isHotelFetchCompleted, hotel, formValues.orderType]);

  const savedOrderType = localStorage.getItem("orderType");

  // console.log("form values in indexxxxxxxxx", formValues)
  // console.log("get order tpes in indexxxxxxxx", getOrderTypes)
  useEffect(() => {
    if (
      !isMultipleOrderType &&
      getOrderTypes.length > 0 &&
      !isOrderTypeUpdated
    ) {
      // const availableOne = getOrderTypes?.find((s) => s.active === true);
      // console.log("avilable oneeeeee", availableOne)
      // setFormValues({ orderType: availableOne?.type || null });
      // setOrderTypeUpdates(true);
    }
  }, [getOrderTypes, isMultipleOrderType, isOrderTypeUpdated, setFormValues]);

  const onSelectOrderType = (fieldName, val) => {
    // console.log("field nameeeeeeeee", fieldName);
    // console.log("valueeeeee", val);
    if (fieldName === "orderType") {
      // setFormValues({ [fieldName]: val });
      localStorage.setItem("orderType", val);
      setFormValues({
        ...formValues,
        orderType: val,
        customer_address: null,
        customer_city: null,
        customer_zipcode: null,
      });
      dispatch(clearLocation());
      if (selectedCoupon?.id) {
        RemoveItemCoupon(selectedCoupon);
      }
      dispatch(clearCoupon());
    }

    // setOpenOrderTypeModal(false);
    dispatch(resetWallet());
    resetWalletProducts(2);
    // dispatch(setOrderTypeModal(false));
    setFormValues({ [fieldName]: val });
  };

  const handleClose = () => {
    setOpenOrderTypeModal(false);
  };

  const onDateAndTimeSelect = (deliveryType, deliveryTime) => {
    onSelectOrderType("deliveryType", deliveryType);
    onSelectOrderType("deliveryTime", deliveryTime);
  };

  const checkHoliday = () => {
    let match;
    if (hotel?.shop_hoilidays && hotel?.shop_hoilidays?.length > 0) {
      match = hotel?.shop_hoilidays?.find(
        (h) => moment().format("YYYY-MM-DD") === h.date.toString()
      );
    }

    return match;
  };

  return (
    <>
      {!isErrorMode && children}

      {/* {!formValues.orderType && isMultipleOrderType && !tableId && ( */}
      {/* {isMultipleOrderType && !tableId && !savedOrderType && ( */}
      {!tableId && !isProfilePage && isMultipleOrderType && !loginOpen && (
        <Modal
          // open={openOrderTypeModal || orderTypeModal}
          open={openOrderTypeModal}
          customView={true}
          modelContainer={
            isMobile
              ? classes.orderTypeModalMobileContainer
              : classes.orderTypeModalContainer
          }
        >
          <div className={classes.orderTypeModal}>
            <SelectOrderType
              value={formValues.orderType}
              onSelect={onSelectOrderType}
              formValues={formValues}
              handleClose={handleClose}
              onDateAndTimeSelect={onDateAndTimeSelect}
            />
          </div>
        </Modal>
      )}
      <Dialog
        maxWidth="sm"
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.dialogContent}>
          <LoginDialogCon setOpen={setLoginOpen} directLogin={true} />
        </DialogContent>
      </Dialog>

      {/* )} */}
      <ErrorModal />
    </>
  );
}

export default InnerLayout;
